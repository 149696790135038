<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card
      class="mx-auto v-card--wizard"
      elevation="12"
      max-width="700"
    >
      <v-card-title
        class="justify-center display-2 font-weight-light pt-5"
        style="word-break: break-word"
      >
        Selecione o tipo de Ajuste de BDGD
      </v-card-title>
      <v-card-text class="my-6">
        <general-card-item-group
          v-model="tipoAtualizacao"
          :optionalId="true"
          :options="cardItemOptions"
          justify="center"
          @input="redirect($event)"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import GeneralCardItemGroup from '@/components/general/GeneralCardItemGroup';
import routes from '@/store/modules/routes';

export default {
  components: {
    GeneralCardItemGroup
  },
  data: () => ({
    tipoAtualizacao: '',
    cardItemOptions: [
      {
        value: 'EXCEL',
        text: 'Via Excel',
        description: 'Efetue ajustes utilizando arquivos Excel.',
        icon: 'mdi-microsoft-excel'
      },
      {
        value: 'SQL',
        text: 'Massivo',
        description: 'Rode atualizações massivas na entidade da BDGD.',
        icon: 'mdi-database-sync-outline'
      },
      {
        value: 'SCRIPT-MODELO',
        text: 'Via Script Modelo',
        description: 'Rode atualizações massivas na entidade da BDGD.',
        icon: 'mdi-file-code-outline'
      }
    ]
  }),
  methods: {
    redirect(param) {
      if (param) {
        this.$router.push({
          name: this.getRouteName(param)
        });
      }
    },
    getRouteName(param) {
      let routeName = routes.state.appRoutes['ATUALIZACOES_DE_BDGD_ADICIONAR'];
      if (param === 'SQL') {
        routeName =
          routes.state.appRoutes['ATUALIZACOES_DE_BDGD_SQL_ADICIONAR'];
      } else if (param === 'SCRIPT-MODELO') {
        routeName =
          routes.state.appRoutes[
            'ATUALIZACOES_DE_BDGD_SCRIPT_MODELO_ADICIONAR'
          ];
      }
      return routeName;
    }
  }
};
</script>
