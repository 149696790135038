<template>
  <v-container fluid>
    <v-item-group
      v-model="selected"
      @change="$emit('input', selected)"
      :class="`row justify-${justify}`"
      :mandatory="mandatory"
    >
      <v-item
        v-for="option in options"
        :id="optionalId ? 'general-card-item-' + option.value : undefined"
        :key="option.value"
        :value="option.value"
      >
        <template v-slot="{ active, toggle }">
          <v-col
            class="text-center"
            cols="12"
            sm="4"
          >
            <v-card
              :class="active ? 'success--text' : 'grey--text'"
              :style="option.disabled ? 'opacity: 0.6' : ''"
              class="mb-6 mx-auto pa-10 d-inline-block v-card--account"
              outlined
              @click="toggle"
              :disabled="option.disabled"
            >
              <v-icon
                size="60"
                v-text="option.icon"
              />
            </v-card>
            <div
              :style="option.disabled ? 'opacity: 0.6' : ''"
              class="text-uppercase subtitle-2 text--primary"
              v-text="option.text"
            />
          </v-col>
        </template>
      </v-item>
    </v-item-group>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    optionalId: {
      type: Boolean,
      required: false,
      default: undefined
    },
    options: {
      type: Array,
      default: () => []
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    justify: {
      type: String,
      default: 'start'
    }
  },

  data: () => ({
    selected: ''
  })
};
</script>

<style lang="sass">
.v-card.v-card.v-card--account
  border-color: currentColor
  border-width: 4px

  .v-icon
    color: inherit

.v-card--account,
.v-card--account:before
  border-radius: 50%
</style>
